@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';
@import '@veamstudios/annotator-sdk/styles';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  background: #edf2f7;
}

/******************************************************
 *  NEW UI
 ******************************************************/
.list-ul {
  @apply divide-y divide-gray-100 dark:divide-gray-600;
}
.list-row {
  @apply flex justify-between gap-x-4 py-5;
}
.list-a {
  @apply flex w-full flex-row gap-x-4; // items-center
}
.list-title {
  @apply text-base font-medium leading-6 text-text_Title group-hover:text-brand_Primary dark:text-white;
}

.list-meta {
  @apply flex text-sm leading-5 text-text_Subtitle group-hover:text-brand_Primary dark:text-gray-400;
}

.list-meta-second {
  @extend .list-meta;
  @apply mt-1;
}

.list-icon {
  @apply mr-2.5 block h-full pl-2.5 text-text_Icon dark:text-gray-400 sm:mr-0;
}

.btn {
  @apply flex w-auto flex-shrink-0 justify-center rounded-md px-3 py-2 text-sm font-medium shadow-sm sm:w-auto;
}

.btn-cancel {
  @apply bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50;
}

.btn-primary {
  @apply mt-3 gap-x-1.5 bg-brand_Primary text-white hover:bg-brand_Primary/50 sm:mt-0;
}

.btn-secondary {
  @apply bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50;
}

.btn-tertiary {
  @apply flex w-auto flex-shrink-0 justify-center rounded-md bg-brand_tertiary py-2 text-sm font-medium shadow-sm sm:w-auto;
}

.btn-secondary-small {
  @apply cursor-pointer rounded-md px-3 py-0.5 text-sm text-gray-900 shadow-sm ring-1 ring-inset;
}

.btn-secondary-small-default {
  @extend .btn-secondary-small;
  @apply ring-gray-300 hover:bg-gray-50;
}
.btn-secondary-small-disabled {
  @extend .btn-secondary-small;
  color: #9da9a6;
  @apply cursor-not-allowed bg-bg_Disabled ring-gray-300 hover:bg-bg_Disabled;
}

.btn-secondary-small-active {
  @extend .btn-secondary-small;
  @apply bg-brand_Primary text-white hover:bg-brand_Primary/50;
}

.btn-destructive {
  @apply bg-brand_Destructive text-white hover:opacity-75;
}
.btn-destructive-light {
  @apply bg-brand_Destructive bg-opacity-25 text-brand_Destructive hover:opacity-75;
}

.page-header-wrapper {
  @apply mx-auto max-w-7xl px-6 pb-8;
}
.page-header {
  @apply text-2xl font-bold leading-7 text-text_Page_Header dark:text-white sm:truncate sm:text-3xl sm:tracking-tight;
}

.btn-disabled {
  color: #9da9a6;
  @apply cursor-not-allowed bg-bg_Disabled hover:bg-bg_Disabled;
}

.form-header-wrapper {
  @apply border-b border-gray-200 pb-5 dark:border-gray-700;
}
.form-header-text {
  @apply text-base font-medium leading-6 text-text_Page_Header dark:text-white;
}
.form-input-wrapper {
  @apply w-full rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 dark:bg-gray-700 dark:ring-gray-600;
}
.form-input-label {
  @apply block text-left text-sm text-brand_Primary dark:text-gray-400;
}
.form-input {
  @apply block w-full border-0 p-0 text-base text-text_Title placeholder:text-text_Placeholder focus:ring-0 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-700 sm:leading-6;
}
.form-input-wrapper textarea {
  @apply min-h-[160px];
}

.form-checkbox:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.form-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  // color-adjust: exact;
  print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  flex-shrink: 0;
  background-color: #fff;
  border-width: 1px;
  border-radius: 0.25rem;
  @apply h-5 w-5 border-gray-400 text-brand_Primary;
}

.form-checkbox:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  border-color: #63b3ed;
}

#cip-date-picker {
  .mat-input-element {
    @apply text-base;
  }
}

/** Sub Menus **/
.subMenu-wrapper {
  @apply block;
}
.subMenu-ul {
  @apply divide-y divide-slate-100 rounded-md border border-slate-100 bg-white shadow-sm;
}
.subMenu-a {
  @apply flex flex-row items-center space-x-4 border-l-2 border-transparent px-4 py-4 hover:text-brand_Primary-hover;
}
.subMenu-a.active {
  .subMenu-title {
    @apply text-brand_Primary dark:text-sky-500;
  }
}
.subMenu-title {
  @apply pb-0.5 text-base font-normal text-text_Title group-hover:text-brand_Primary-hover dark:text-slate-300;
}
.subMenu-description {
  @apply text-sm font-normal text-text_Subtitle group-hover:text-brand_Primary-hover dark:text-slate-500;
}

.container-header {
  @apply px-6 pb-2 text-xs uppercase tracking-wider text-text_Subtitle sm:px-8;
}

.footer-text {
  @apply py-2 text-xs text-text_Subtitle;
}

.required-field {
  @apply pt-2 text-xs text-alert_Error;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  @apply border-b-brand_Primary;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/** REPORTS **/

#report-content {
  background-color: white;
}

#content-wrapper {
  font-family: 'Satoshi', sans-serif;
}

/***************************/
/**** FONT SIZES ****/
/***************************/
.font-size-0 .text-group-1 {
  font-size: 33px;
  line-height: 33px;
}
.font-size-0 .text-group-2 {
  font-size: 20px;
  line-height: 25px;
}
.font-size-0 .text-group-3 {
  font-size: 15px;
  line-height: 19px;
}
.font-size-0 .text-group-4 {
  font-size: 12px;
  line-height: 21px;
}
.font-size-0 .text-group-5 {
  font-size: 12px;
  line-height: 16px;
}
.font-size-0 .text-group-6 {
  font-size: 11px;
  line-height: 16px;
}
.font-size-0 .item-photos svg,
.font-size-0 .item-actions svg,
.font-size-0 .action-priority svg {
  width: 15px;
  height: 15px;
}
.font-size-0 .item-result svg {
  width: 14px;
  height: 14px;
}
.font-size-0 .action-complete svg,
.font-size-0 .action-incomplete svg {
  width: 14px;
  height: 14px;
}
.font-size-1 .text-group-1 {
  font-size: 34px;
  line-height: 34px;
}
.font-size-1 .text-group-2 {
  font-size: 21px;
  line-height: 26px;
}
.font-size-1 .text-group-3 {
  font-size: 16px;
  line-height: 20px;
}
.font-size-1 .text-group-4 {
  font-size: 13px;
  line-height: 22px;
}
.font-size-1 .text-group-5 {
  font-size: 13px;
  line-height: 17px;
}
.font-size-1 .text-group-6 {
  font-size: 12px;
  line-height: 17px;
}
.font-size-1 .item-photos svg,
.font-size-1 .item-actions svg,
.font-size-1 .action-priority svg {
  width: 16px;
  height: 16px;
}
.font-size-1 .item-result svg {
  width: 16px;
  height: 16px;
}
.font-size-1 .action-complete svg,
.font-size-1 .action-incomplete svg {
  width: 15px;
  height: 15px;
}
.font-size-2 .text-group-1 {
  font-size: 35px;
  line-height: 35px;
}
.font-size-2 .text-group-2 {
  font-size: 22px;
  line-height: 27px;
}
.font-size-2 .text-group-3 {
  font-size: 17px;
  line-height: 21px;
}
.font-size-2 .text-group-4 {
  font-size: 14px;
  line-height: 23px;
}
.font-size-2 .text-group-5 {
  font-size: 14px;
  line-height: 18px;
}
.font-size-2 .text-group-6 {
  font-size: 13px;
  line-height: 18px;
}
.font-size-2 .item-photos svg,
.font-size-2 .item-actions svg,
.font-size-2 .action-priority svg {
  width: 16px;
  height: 16px;
}
.font-size-2 .item-result svg {
  width: 18px;
  height: 18px;
}
.font-size-2 .action-complete svg,
.font-size-2 .action-incomplete svg {
  width: 16px;
  height: 16px;
}
.font-size-3 .text-group-1 {
  font-size: 36px;
  line-height: 36px;
}
.font-size-3 .text-group-2 {
  font-size: 23px;
  line-height: 28px;
}
.font-size-3 .text-group-3 {
  font-size: 18px;
  line-height: 22px;
}
.font-size-3 .text-group-4 {
  font-size: 15px;
  line-height: 24px;
}
.font-size-3 .text-group-5 {
  font-size: 15px;
  line-height: 19px;
}
.font-size-3 .text-group-6 {
  font-size: 14px;
  line-height: 19px;
}
.font-size-3 .item-photos svg,
.font-size-3 .item-actions svg,
.font-size-3 .action-priority svg {
  width: 18px;
  height: 18px;
}
.font-size-3 .item-result svg {
  width: 20px;
  height: 20px;
}
.font-size-3 .action-complete svg,
.font-size-3 .action-incomplete svg {
  width: 18px;
  height: 18px;
}
.font-size-4 .text-group-1 {
  font-size: 37px;
  line-height: 37px;
}
.font-size-4 .text-group-2 {
  font-size: 24px;
  line-height: 29px;
}
.font-size-4 .text-group-3 {
  font-size: 19px;
  line-height: 23px;
}
.font-size-4 .text-group-4 {
  font-size: 16px;
  line-height: 25px;
}
.font-size-4 .text-group-5 {
  font-size: 16px;
  line-height: 20px;
}
.font-size-4 .text-group-6 {
  font-size: 15px;
  line-height: 20px;
}
.font-size-4 .item-photos svg,
.font-size-4 .item-actions svg,
.font-size-4 .action-priority svg {
  width: 19px;
  height: 19px;
}
.font-size-4 .item-result svg {
  width: 22px;
  height: 22px;
}
.font-size-4 .action-complete svg,
.font-size-4 .action-incomplete svg {
  width: 19px;
  height: 19px;
}

/***************************/
/**** CHARTS ****/
/***************************/
.action-chart-date,
.action-chart-percent-text {
  text-align: right;
  font-size: 12px;
  line-height: 17px;
}
.action-chart-date,
.action-chart-percent,
.action-chart-percent-text,
.action-chart-percentage-x div,
.assignee-chart-action-priority,
.chart-total,
.percentage-axis-wrapper {
  font-style: normal;
  font-weight: 500;
}
.action-chart-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.action-chart-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
  height: 30px;
}
.action-chart-date {
  min-width: 112px;
  color: #61788d;
  margin-right: 8px;
}
.action-chart-data-wrapper {
  display: flex;
  flex-grow: 1;
  background: #e7edf8;
  position: relative;
}
.action-chart-percent {
  color: #fff;
  font-size: 12px;
  line-height: 17px;
  height: 30px;
}
.action-chart-percent-text {
  position: absolute;
  height: 30px;
  padding: 6px;
}
.action-chart-measurement {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
}
.action-chart-percentage-x {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  padding-left: 114px;
}
.action-chart-percentage-x div {
  color: #61788d;
  font-size: 10px;
  line-height: 17px;
}
.chart-total {
  color: #27323c;
  border-radius: 5px;
  border: 1px solid #d0dae2;
  background: #f4f7fa;
  padding: 4px 9px;
  font-style: normal;
  font-weight: 500;
  margin-right: 15px;
  display: inline-block;
  margin-bottom: 13px;
}
.actions {
  background: #ffffff;
}

.assignee-chart-wrapper .chart-key-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr) auto;
  gap: 10px;
  width: 100%;
  margin-bottom: 13px;
}
.overall-chart-wrapper .chart-key-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 13px;
}

.chart-key-text-wrapper {
  flex-direction: row;
  display: flex;
  align-items: center;
}
.assignee-chart-data-wrapper {
  flex-direction: row;
  display: flex;
}
.chart-key-item {
  display: flex;
  flex-direction: column;
}
.chart-key {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  margin-right: 4px;
}
.chart-key-count {
  color: #27323c;
  font-size: 16px;
  font-weight: bold;
}
.assignee-action-priority-none,
.chart-key-no-priority {
  background: #94a3b8;
}
.assignee-action-priority-trivial,
.chart-key-trivial {
  background: #417ce9;
}
.chart-key-100 {
  background: #4caf50;
}
.assignee-action-priority-low,
.chart-key-low {
  background: #4caf50;
}
.assignee-action-priority-medium,
.chart-key-50,
.chart-key-medium {
  background: #ffeb3b;
}
.assignee-action-priority-high,
.chart-key-high {
  background: #ff9100;
}
.assignee-action-priority-critical,
.chart-key-0,
.chart-key-critical {
  background: #f44336;
}
.assignee-chart-count,
.assignee-chart-name,
.chart-text {
  color: #61788d;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
}
.assignee-chart-user {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
}
.assignee-chart-count {
  margin-left: 4px;
}
.assignee-chart-bar-wrapper {
  flex-grow: 1;
}
.assignee-chart-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.assignee-chart-action-data {
  background: #e7edf8;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  height: 30px;
}
.assignee-chart-action-priority {
  height: 100%;
  font-size: 12px;
  line-height: 17px;
  align-items: center;
  display: flex;
  box-sizing: border-box;
}
.percentage-axis-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #61788d;
  font-size: 10px;
  line-height: 17px;
  margin-top: 12px;
}
.chart-svg-wrapper {
  display: flex;
  justify-content: center;
}

/***************************/
/**** PHOTO SIZES ****/
/***************************/
.photo-wrapper {
  margin-left: -19px;
  font-size: 0;
}
.photo-content {
  display: inline-block;
  vertical-align: top;
  margin: 21px 0 0 19px;
}
.photo {
  overflow: hidden;
  position: relative;
}
.photo-annotation {
  position: absolute;
  top: 0;
  left: 0;
}
.photo-0 .photo {
  max-width: 126px;
}
.photo-1 .photo {
  max-width: 163px;
}
.photo-2 .photo {
  max-width: 223px;
}
.photo-3 .photo {
  max-width: 345px;
}
.photo-4 .photo {
  max-width: 709px;
}
.photo-0 .photo img {
  max-height: 126px;
}
.photo-1 .photo img {
  max-height: 163px;
}
.photo-2 .photo img {
  max-height: 223px;
}
.photo-3 .photo img {
  max-height: 345px;
}
.photo-4 .photo img {
  max-height: 709px;
}

/** SQUARE **/
.square-photos.photo-0 .photo {
  width: 126px;
  height: 126px;
}
.square-photos.photo-1 .photo {
  width: 163px;
  height: 163px;
}
.square-photos.photo-2 .photo {
  width: 223px;
  height: 223px;
}
.square-photos.photo-3 .photo {
  width: 345px;
  height: 345px;
}
.square-photos.photo-4 .photo {
  width: 709px;
  height: 709px;
}
.squareImg {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

/******************************************************
 *  REPORTS MISC
 ******************************************************/
.disabled-pill {
  border-radius: 5px;
  border: 1px solid #d0dae2;
  background: #f4f7fa;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 500;
  display: inline-flex;
  padding: 4px 9px;
  align-items: center;
  color: #9da9a6 !important; /** Overrides the inline custom user colour **/
}

/******************************************************
 *  NEW UI
 ******************************************************/

/** Titles **/
.page-title {
  @apply text-4xl font-bold text-text_Title dark:text-slate-300;
}

/** Lists - Template Items Variation **/
.templateListDefault {
  .template-list-value {
    @apply flex flex-1 font-normal text-text_Title dark:text-slate-300;
  }
}

/** Overlays **/
.overlay-title {
  @apply text-xl font-bold text-text_Title dark:text-slate-300;
}

/** Misc **/
.cip-shadow {
  box-shadow: 0 2px 24px 0 rgba(90, 102, 131, 0.08);
}

.cip-transition {
  @apply transition-all duration-500 ease-in-out;
}

/** Inputs **/
.text-input {
  @apply w-full rounded-md border border-[#EAEAEA] px-5 py-2 text-text_Title placeholder-gray-500 focus-within:border-brand_Primary focus-within:ring-1 focus-within:ring-brand_Primary focus:ring-0 dark:border-slate-500 dark:bg-slate-700 dark:text-slate-300 dark:focus-within:border-slate-400 dark:focus-within:ring-slate-400;
}

.text-input-selector {
  @apply hover:focus-within:ring-1 hover:focus-within:ring-brand_Primary hover:focus:ring-0 hover:dark:border-slate-500 hover:dark:bg-zinc-800 hover:dark:text-slate-300 hover:dark:focus-within:border-slate-400 hover:dark:focus-within:ring-slate-400;
}

/** Angular Material Date Picker **/
@at-root #cip-date-picker {
  .mat-form-field-wrapper {
    @apply p-0;
  }
  .mat-form-field {
    @apply w-full;
  }
  .mat-form-field-underline {
    @apply hidden;
  }
  .mat-form-field-infix {
    @apply border-none p-0;
  }
  .text-label-input input {
  }
  .mat-form-field-suffix {
    position: absolute;
    right: 0;
    width: 16px;
    height: 16px;
    top: 3px;
  }
  .mat-datepicker-toggle-default-icon {
    margin-top: -8px;
  }
  .mat-button-focus-overlay {
    @apply hidden;
  }
}

/** Angular Drag and Drop CDK **/
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/** MAIN NAVIGATION **/
.menu-item {
  @apply z-20 inline-flex h-20 w-full flex-shrink-0 items-center justify-center rounded-lg text-[#C8C8C8] hover:text-brand_Primary-hover;
}
.menu-item-icon {
  @apply h-6 w-6;
}
.menu-item.active {
  .menu-item-icon {
    @apply text-brand_Primary dark:text-sky-500;
  }
}

.content-card-wrapper {
  @apply w-full overflow-hidden rounded-md bg-white shadow-sm dark:bg-gray-800;
}
.content-card-header {
  @apply border-b border-gray-200 bg-white px-6 py-5;
  h3 {
    @apply text-base font-semibold leading-6 text-gray-900;
  }
}
.content-card {
  @apply px-6 py-5;
}
.content-grid {
  @apply grid;
  // gap-y-6;
  //  gap-x-4 for form inputs
}

.mat-simple-snackbar .mat-button-wrapper {
  @apply text-white;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
